"use strict";

exports.__esModule = true;
exports.default = void 0;

var _default = function _default(value) {
  return value == null;
};

exports.default = _default;
module.exports = exports["default"];