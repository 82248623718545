"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.getIn = getIn;
exports.default = void 0;

var _propertyExpr = require("property-expr");

var _has = _interopRequireDefault(require("lodash/has"));

var trim = function trim(part) {
  return part.substr(0, part.length - 1).substr(1);
};

function getIn(schema, path, value, context) {
  var parent, lastPart, lastPartDebug; // if only one "value" arg then use it for both

  context = context || value;
  if (!path) return {
    parent: parent,
    parentPath: path,
    schema: schema
  };
  (0, _propertyExpr.forEach)(path, function (_part, isBracket, isArray) {
    var part = isBracket ? trim(_part) : _part;

    if (isArray || (0, _has.default)(schema, '_subType')) {
      // we skipped an array: foo[].bar
      var idx = isArray ? parseInt(part, 10) : 0;
      schema = schema.resolve({
        context: context,
        parent: parent,
        value: value
      })._subType;

      if (value) {
        if (isArray && idx >= value.length) {
          throw new Error("Yup.reach cannot resolve an array item at index: " + _part + ", in the path: " + path + ". " + "because there is no value at that index. ");
        }

        value = value[idx];
      }
    }

    if (!isArray) {
      schema = schema.resolve({
        context: context,
        parent: parent,
        value: value
      });
      if (!(0, _has.default)(schema, 'fields') || !(0, _has.default)(schema.fields, part)) throw new Error("The schema does not contain the path: " + path + ". " + ("(failed at: " + lastPartDebug + " which is a type: \"" + schema._type + "\") "));
      schema = schema.fields[part];
      parent = value;
      value = value && value[part];
      lastPart = part;
      lastPartDebug = isBracket ? '[' + _part + ']' : '.' + _part;
    }
  });
  return {
    schema: schema,
    parent: parent,
    parentPath: lastPart
  };
}

var reach = function reach(obj, path, value, context) {
  return getIn(obj, path, value, context).schema;
};

var _default = reach;
exports.default = _default;