"use strict";

exports.__esModule = true;
exports.default = sortByKeyOrder;

function findIndex(arr, err) {
  var idx = Infinity;
  arr.some(function (key, ii) {
    if (err.path.indexOf(key) !== -1) {
      idx = ii;
      return true;
    }
  });
  return idx;
}

function sortByKeyOrder(fields) {
  var keys = Object.keys(fields);
  return function (a, b) {
    return findIndex(keys, a) - findIndex(keys, b);
  };
}

module.exports = exports["default"];